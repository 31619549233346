import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  IconButton,
  HStack,
  ListItem,
  List,
  Modal,
  Divider,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Center,
  RadioGroup,
  Radio
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

const New = ({ request, setRequest }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    corpName: "",
    corpAccessNumber: "",
    phone: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "Alberta",
  });

  const [modalShareholderType, setModalShareholderType] = useState('individual');

  const handleShareholderAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      corpName: "",
      corpAccessNumber: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
    setShowModal(true);
  };

  const handleEditOfficerShareholder = (shareholder) => {
    setFormData({ ...shareholder });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteShareholder = (key) => {
    if (window.confirm("Are you sure you want to delete this new shareholder?")) {
      const updatedNewShareholders = request.shareholders.newShareholders.filter(
        (shareholder) => shareholder.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          newShareholders: updatedNewShareholders,
          result: [
            ...prevRequest.shareholders.currentShareholders,
            ...updatedNewShareholders,
          ].filter(
            (shareholder) =>
              !prevRequest.shareholders.deletedShareholders.includes(
                shareholder.key
              )
          ),
        }
      }));

      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        corpName: "",
        corpAccessNumber: "",
        phone: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "Alberta",
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    setRequest((prevRequest) => {
      const updatedNewShareholders = isEditable
        ? prevRequest.shareholders.newShareholders.map((shareholder) =>
            shareholder.key === formData.key
              ? { ...shareholder, ...formData }
              : shareholder
          )
        : [...prevRequest.shareholders.newShareholders, formData];

      const updatedShareholders = {
        ...prevRequest.shareholders,
        newShareholders: updatedNewShareholders,
        result: [
          ...prevRequest.shareholders.currentShareholders,
          ...updatedNewShareholders,
        ].filter(
          (shareholder) =>
            !prevRequest.shareholders.deletedShareholders.includes(
              shareholder.key
            )
        ),
      };

      return {
        ...prevRequest,
        shareholders: updatedShareholders,
      };
    });

    setShowModal(false);
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      corpName: "",
      corpAccessNumber: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
    setIsEditable(false);
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const {
      firstName,
      lastName,
      corpName,
      corpAccessNumber,
      phone,
      email,
      streetLine1,
      city,
      postalCode,
      province,
      country,
    } = formData;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const postalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

    const newErrors = {};

    if (modalShareholderType === 'individual'){
      if (!firstName) newErrors.firstName = "First Name is required";
      if (!lastName) newErrors.lastName = "Last Name is required";
    } else if (modalShareholderType === 'corporation') {
      if (!corpName) newErrors.corpName = "Corporate Name is required";
      if (!corpAccessNumber) newErrors.corpAccessNumber = "Corporate Access Number is required";
    }

    if (!phone) newErrors.phone = "Phone number is required";
    if (!email || !emailPattern.test(email))
      newErrors.email = "Valid email is required";
    if (!streetLine1) newErrors.streetLine1 = "Street Line 1 is required";
    if (!city) newErrors.city = "City is required";
    if (!postalCode || !postalCodePattern.test(postalCode))
      newErrors.postalCode = "Valid postal code is required";
    if (!province) newErrors.province = "Province is required";
    if (!country) newErrors.country = "Country is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <HStack justify={"space-between"}>
        <Heading as="h4" color="green.700">
          New Shareholders
        </Heading>
        <Button
          colorScheme="green"
          variant="solid"
          leftIcon={<AddIcon />}
          onClick={handleShareholderAdd}
        >
          Add
        </Button>
      </HStack>
      <FormLabel>
        Shareholders that are being <i>added</i> are as follows:
      </FormLabel>
      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="green.500"
        >
          {request.shareholders.newShareholders.map((shareholder, index) => (
            <ListItem key={shareholder.key}>
              <HStack justify="space-between">
                <Text width="60%" fontWeight="bold" mb="0 !important">
                  {shareholder.firstName} {shareholder.lastName}
                  {shareholder.corpName} {shareholder.corpAccessNumber}
                </Text>
                <HStack>
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() => handleEditOfficerShareholder(shareholder)}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteShareholder(shareholder.key)}
                  />
                </HStack>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider h="50px" />
      
      <Modal isOpen={showModal} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable ? "Edit Shareholder" : "Add Shareholder"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          {!isEditable && (
            <Center>
              <RadioGroup
                onChange={setModalShareholderType}
                value={modalShareholderType}
                mb="24px"
              >
                <HStack>
                  <Radio value='individual'>Individual</Radio>
                  <Radio value='corporation'>Corporation</Radio>
                </HStack>
              </RadioGroup>
            </Center>
            )}

            {((!isEditable && modalShareholderType === 'individual') || formData.firstName !== "") && (
            <>
            <FormControl isInvalid={errors.firstName}>
              <FormLabel>First Name:</FormLabel>
              <Input
                placeholder=""
                name="firstName"
                value={formData.firstName}
                borderBottom="solid 1px black"
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.firstName ? "red.500" : "gray.200"}
              />
              {errors.firstName && <Text color="red.500">{errors.firstName}</Text>}
            </FormControl>
            <FormControl isInvalid={errors.lastName}>
              <FormLabel>Last Name:</FormLabel>
              <Input
                placeholder=""
                name="lastName"
                value={formData.lastName}
                borderBottom="solid 1px black"
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.lastName ? "red.500" : "gray.200"}
              />
              {errors.lastName && <Text color="red.500">{errors.lastName}</Text>}
            </FormControl>
            </>
            )}

            {((!isEditable && modalShareholderType === 'corporation') || formData.corpName !== "") && (
            <>
            <FormControl isInvalid={errors.corpName}>
              <FormLabel>Corporation Name:</FormLabel>
                <Input
                  placeholder=""
                  name="corpName"
                  value={formData.corpName}
                  borderBottom="solid 1px black"
                  onChange={handleFormChange}
                  mb="4"
                  borderColor={errors.corpName ? "red.500" : "gray.200"}
                />
                {errors.corpName && (
                  <Text color="red.500">{errors.corpName}</Text>
                )}
            </FormControl>
            <FormControl isInvalid={errors.corpAccessNumber}>
              <FormLabel>Corporate Access Number:</FormLabel>
              <Input
                placeholder=""
                name="corpAccessNumber"
                value={formData.corpAccessNumber}
                borderBottom="solid 1px black"
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.corpAccessNumber ? "red.500" : "gray.200"}
              />
              {errors.corpAccessNumber && (
                <Text color="red.500">{errors.corpAccessNumber}</Text>
              )}
            </FormControl>
            </>
            )}

            <FormControl isInvalid={errors.phone}>
              <FormLabel>Phone:</FormLabel>
              <Input
                placeholder=""
                name="phone"
                value={formData.phone}
                borderBottom="solid 1px black"
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.phone ? "red.500" : "gray.200"}
              />
              {errors.phone && <Text color="red.500">{errors.phone}</Text>}
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <FormLabel>Email:</FormLabel>
              <Input
                placeholder=""
                name="email"
                value={formData.email}
                borderBottom="solid 1px black"
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.email ? "red.500" : "gray.200"}
              />
              {errors.email && <Text color="red.500">{errors.email}</Text>}
            </FormControl>
            <FormControl>
              <Text fontWeight="bold">Residential Address (current):</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isInvalid={errors.streetLine1}>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input
                        variant="outline"
                        borderColor={errors.streetLine1 ? "red.500" : "orange"}
                        borderRadius="md"
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                      {errors.streetLine1 && <Text color="red.500">{errors.streetLine1}</Text>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        variant="outline"
                        borderColor="orange"
                        borderRadius="md"
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isInvalid={errors.city}>
                      <FormLabel>City</FormLabel>
                      <Input
                        variant="outline"
                        borderColor={errors.city ? "red.500" : "orange"}
                        borderRadius="md"
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                      {errors.city && <Text color="red.500">{errors.city}</Text>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isInvalid={errors.postalCode}>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        variant="outline"
                        borderColor={errors.postalCode ? "red.500" : "orange"}
                        borderRadius="md"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                      {errors.postalCode && <Text color="red.500">{errors.postalCode}</Text>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isInvalid={errors.province}>
                      <FormLabel>Province</FormLabel>
                      <Input
                        variant="outline"
                        borderColor={errors.province ? "red.500" : "orange"}
                        borderRadius="md"
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                      {errors.province && <Text color="red.500">{errors.province}</Text>}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isInvalid={errors.country}>
                      <FormLabel>Country</FormLabel>
                      <Input
                        variant="outline"
                        borderColor={errors.country ? "red.500" : "orange"}
                        borderRadius="md"
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                      {errors.country && <Text color="red.500">{errors.country}</Text>}
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default New;
