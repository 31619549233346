import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Button,
  HStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import NavBar from "../../../utils/NavBar";
import Payment from "../../new/register/Payment";
import Review from "../../new/register/Review";
import IncServInformation from "./BusinessInfo";
import Disclaimer from "../../../utils/Disclaimer";
import AccountInformation from "../../existing/incorporation/changeIndividuals/AccountInformation";
import Shareholders from "../../existing/incorporation/changeIndividuals/Shareholders";
import DirectorsAndOfficersInfo from "./../../existing/incorporation/changeIndividuals/Directors";
import AgentForService from "./Agent";
import { snappyCorpPriceList } from "../../../services/price";
import ModalDisclaimer from "../../../utils/ModalDisclaimer";

const AnnualReturn = ({ bType }) => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [request, setRequest] = useState({
    serviceType: "AnnualReturn",
    price: snappyCorpPriceList.AnnualReturns,
    prices: {
      default: snappyCorpPriceList.AnnualReturns,
      SubscribeAnnualMaintenance: {
        value: snappyCorpPriceList.SnappyAnnualSubscription,
        enabled: false,
      },
      ChangeDirectors: {
        value: snappyCorpPriceList.ChangeDirectors,
        enabled: false,
      },
    },
    accountInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    businessInfo: {
      businessName: "",
      corporateAccessNumber: "",
      yearEnding: "2024",
      dateIncorporated: "",
      directorChange: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "Alberta",
      country: "Canada",
      postalCode: "",
    },
    directors: {
      currentDirectors: [],
      newDirectors: [],
      deletedDirectors: [],
      modifiedDirectors: [],
      results: [],
    },
    yesno: {
      ChangeShareholders: { enabled: false },
    },
    agent: {
      firstName: "",
      lastName: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "Calgary",
      postalCode: "",
      province: "Alberta",
      country: "Canada",
    },
    shareholders: {
      currentShareholders: [],
      newShareholders: [],
      deletedShareholders: [],
      modifiedShareholders: [],
      results: [],
    },
    // NOTE: the Review feature was modelled in Annual Returns
    // sections that have required fields
    review: [
      'accountInfo',
      'businessInfo',
      'agent',
      'shareholders',
      'directors'
    ],
    // count of required fields, excluding hidden ones
    reviewFieldCount: 0,
    // count of required fields (excluding hidden ones) without data
    reviewFieldNoDataCount: 0,
    // cannot click on Payment until review has been done
    reviewed: false,
    // field that are NOT required; easier this way..
    reviewFieldNotRequired: {
      businessInfo: {
        streetLine2: false
      },
      agent: {
        streetLine2: false
      }
    },
    // fields that need to be hidden from Review
    reviewFieldHidden: {
      businessInfo: {
        directorChange: false
      },
      shareholders: {
        result: false,
        results: false, 
        modifiedShareholders: false
      },
      directors: {
        result: false,
        results: false, 
        modifiedDirectors: false
      }
    },
    // fields that are read-only (like lists)
    reviewFieldReadOnly: {
      businessInfo: {
        yearEnding: false
      }
    },
    toggleYesNo: (value, type) => {
      setRequest((prevRequest) => {
        let bool = value === "Yes" ? true : false;
        let updatedYesNo = prevRequest.yesno;

        updatedYesNo[type].enabled = bool;

        return {
          ...prevRequest,
          yesno: updatedYesNo,
        };
      });
    },
    togglePrice: (value, priceType) => {
      setRequest((prevRequest) => {
        let bool = value === "Yes" ? true : false;
        let updatedPrices = prevRequest.prices;

        updatedPrices[priceType].enabled = bool;

        return {
          ...prevRequest,
          prices: updatedPrices,
        };
      });

      request.updatePrice();
    },
    updatePrice: () => {
      setRequest((prevRequest) => {
        let updatedPrice = 0;

        updatedPrice += prevRequest.prices.default;

        for (const key in prevRequest.prices) {
          if (prevRequest.prices[key].enabled === true) {
            updatedPrice += prevRequest.prices[key].value;
          }
        }

        return {
          ...prevRequest,
          price: updatedPrice,
        };
      });
    },
  });

  //   // Define sections for each company type
  const sectionsMap = [
    "accountInformation",
    "businessInformation",
    "agentForService",
    "Shareholders",
    "directors",
    "review",
    "payment",
  ];

  const handlePrevious = () => {
    setSelectedSectionIndex((prevIndex) => Math.max(0, prevIndex - 1));
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setSelectedSectionIndex((prevIndex) => 
      Math.min(prevIndex + 1, sectionsMap.length - 1)  
    );

    // if (selectedSectionIndex == sectionsMap.length-3){
    //   setRequest((prevReq) => ({
    //     ...prevReq,
    //     reviewed: true
    //   }));
    // }

    window.scrollTo(0, 0);
  };

  const renderMainComponent = () => {
    const sections = sectionsMap;
    const selectedSection = sections[selectedSectionIndex];
    switch (selectedSection) {
      case "accountInformation":
        return <AccountInformation request={request} setRequest={setRequest} />;
      case "businessInformation":
        return <IncServInformation request={request} setRequest={setRequest} />;
      case "agentForService":
        return <AgentForService request={request} setRequest={setRequest} />;
      case "Shareholders":
        return (
          <Shareholders
            request={request}
            setRequest={setRequest}
            native={true}
          />
        );
      case "directors":
        return (
          <DirectorsAndOfficersInfo
            request={request}
            setRequest={setRequest}
            extraCost={true}
          />
        );
      case "review":
        return <Review request={request} setRequest={setRequest} handleNext={handleNext} />;
      case "payment":
        return <Payment request={request} setRequest={setRequest} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar />

      <Center mt="40px" className="formPageContent">
        <VStack alignItems="flex-start" className="container">
          <HStack w="90%" justifyContent="min-content">
            <Heading as="h1" className="formPageHeading">Annual Returns</Heading>
          </HStack>

          <Divider className="divider" />

          <Flex w="100%">
            {/* Sidebar */}
            <Box className="sidebar" w="25%">
              <VStack align="start">
                {sectionsMap?.map((section, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      // if (section == 'payment' && !request.reviewed){
                      //   return false;
                      // }

                      setSelectedSectionIndex(index);

                      // if (index == sectionsMap.length-2){
                      //   setRequest((prevReq) => ({
                      //     ...prevReq,
                      //     reviewed: true
                      //   }));
                      // }
                    }}
                    variant={selectedSectionIndex === index ? "solid" : "ghost"}
                    bg={selectedSectionIndex === index ? "green.700" : ""}
                    w="100%"
                    color={selectedSectionIndex === index ? "white" : /*section == 'payment' && !request.reviewed ? "red" :*/ ""}
                    m={0}
                    _hover={{
                      bg:
                        selectedSectionIndex === index
                          ? "green.700"
                          : "green.400",
                      color: "white", // Set text color for better contrast
                    }}
                  >
                    {section
                      .replace("account", "personal")
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  </Button>
                ))}
              </VStack>
            </Box>

            {/* Main bar */}
            <Box w="75%" className="maincomponent">
              {renderMainComponent()}
              {/* Previous and Next Button */}


              <Flex className="nextprevious">
                <Center width="100%">
                  {selectedSectionIndex !== 0 && (
                    <Button
                      className="btn-black"
                      leftIcon={<ArrowLeftIcon />}
                      onClick={handlePrevious}
                      aria-label="Previous"
                      mr={2}
                      disabled={selectedSectionIndex === 0 || !sectionsMap}
                    >
                      Previous
                    </Button>
                  )}
                  {selectedSectionIndex < sectionsMap.length - 1 && (
                    <Button
                      className="btn-black"
                      rightIcon={<ArrowRightIcon />}
                      onClick={handleNext}
                      aria-label="Next"
                      disabled={
                        selectedSectionIndex === sectionsMap?.length - 1 || !sectionsMap
                      }
                    >
                      Next
                    </Button>
                  )}
                </Center>
              </Flex>
              
            </Box>
          </Flex>
        </VStack>
      </Center>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default AnnualReturn;
