import React from "react";

import {
  FormControl,
  Heading,
  Text,
  Input,
  HStack,
  FormLabel,
  Grid,
  GridItem
} from "@chakra-ui/react";
import Province from "../../../../utils/Province";

const PartnershipInformation = ({ request, setRequest }) => {
  const handleChangeBusinessInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
    }));
  };    
  return (
    <>
      <Heading mb="15px">Partnership Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <HStack>
              <FormLabel pr="0">
                Name of partnership
              </FormLabel>
            </HStack>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.businessName}
              onChange={(e) => handleChangeBusinessInfo("businessName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <HStack>
              <FormLabel pr="20">
                My partnership's email is
              </FormLabel>
            </HStack>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.email}
              onChange={(e) => handleChangeBusinessInfo("email", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <Text mt="24px !important" fontWeight="bold" > 
        The new street address of registered office will be:
      </Text>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={3}
      >
        <GridItem>
          <FormLabel>Address Line 1</FormLabel>
          <Input
            value={request.businessInfo.streetLine1}
            onChange={(e) => handleChangeBusinessInfo("streetLine1", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            value={request.businessInfo.streetLine2}
            onChange={(e) => handleChangeBusinessInfo("streetLine2", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>City</FormLabel>
          <Input
            value={request.businessInfo.city}
            onChange={(e) => handleChangeBusinessInfo("city", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Province</FormLabel>
          <Province
            value={request.businessInfo.province}
            onChange={(e) =>
              handleChangeBusinessInfo("province", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          <FormLabel>Country</FormLabel>
          <Input
            value={request.businessInfo.country}
            onChange={(e) => handleChangeBusinessInfo("country", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Postal Code</FormLabel>
          <Input
            value={request.businessInfo.postalCode}
            onChange={(e) => handleChangeBusinessInfo("postalCode", e.target.value)}
          />
        </GridItem>
      </Grid>
  
    </>
  );
};
export default PartnershipInformation;
