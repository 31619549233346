import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Center
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";
import New from "./shareholders/New";
import Delete from "./shareholders/Delete";
import YesNoField from "../../../../utils/YesNoField.js";

const Shareholders = ({ request, setRequest, native }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    corpName: "",
    corpAccessNumber: "",
    phone: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "Alberta",
    newName: "",
  });

  const [modalShareholderType, setModalShareholderType] = useState('individual');

  const [yesChangeShareholders, setYesChangeShareholders] = useState(native);
  const [noChangeShareholders, setNoChangeShareholders] = useState(false);

  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === "Yes") {
      setYesChangeShareholders(true);
      setNoChangeShareholders(false);
    } else {
      setYesChangeShareholders(false);
      setNoChangeShareholders(true);
    }
  };

  const enabled = request.yesno.ChangeShareholders.enabled;

  const handleShareholderAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      corpName: "",
      corpAccessNumber: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
      newName: "",
    });
    setShowModal(true);
  };

  const handleEditOfficerShareholder = (shareholder) => {
    setFormData({ ...shareholder });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteShareholder = (key) => {
    if (window.confirm("Are you sure you want to delete this current shareholder?")) {
      const updatedCurrentShareholders = request.shareholders.currentShareholders.filter(
        (shareholder) => shareholder.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          currentShareholders: updatedCurrentShareholders,
          result: [
            ...prevRequest.shareholders.newShareholders,
            ...updatedCurrentShareholders,
          ].filter(
            (shareholder) =>
              !prevRequest.shareholders.deletedShareholders.includes(
                shareholder.key
              )
          ),
        }
      }));

      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        corpName: "",
        corpAccessNumber: "",
        phone: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "Alberta",
        newName: "",
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    setRequest((prevRequest) => {
      const updatedCurrentShareholders = isEditable
        ? prevRequest.shareholders.currentShareholders.map((shareholder) =>
            shareholder.key === formData.key
              ? { ...shareholder, ...formData }
              : shareholder
          )
        : [...prevRequest.shareholders.currentShareholders, formData];

      const updatedShareholders = {
        ...prevRequest.shareholders,
        currentShareholders: updatedCurrentShareholders,
        result: [
          ...updatedCurrentShareholders,
          ...prevRequest.shareholders.newShareholders,
        ].filter(
          (shareholder) =>
            !prevRequest.shareholders.deletedShareholders.includes(
              shareholder.key
            )
        ),
      };

      return {
        ...prevRequest,
        shareholders: updatedShareholders,
      };
    });

    setShowModal(false);
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      corpName: "",
      corpAccessNumber: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
      newName: "",
    });
    setIsEditable(false);
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const {
      firstName,
      lastName,
      corpName,
      corpAccessNumber,
      phone,
      email,
      streetLine1,
      city,
      postalCode,
      province,
      country,
    } = formData;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const postalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

    const newErrors = {};

    if (modalShareholderType === 'individual'){
      if (!firstName) newErrors.firstName = "First Name is required";
      if (!lastName) newErrors.lastName = "Last Name is required";
    } else if (modalShareholderType === 'corporation') {
      if (!corpName) newErrors.corpName = "Corporate Name is required";
      if (!corpAccessNumber) newErrors.corpAccessNumber = "Corporate Access Number is required";
    }

    if (!phone) newErrors.phone = "Phone number is required";
    if (!email || !emailPattern.test(email))
      newErrors.email = "Valid email is required";
    if (!streetLine1) newErrors.streetLine1 = "Street Line 1 is required";
    if (!city) newErrors.city = "City is required";
    if (!postalCode || !postalCodePattern.test(postalCode))
      newErrors.postalCode = "Valid postal code is required";
    if (!province) newErrors.province = "Province is required";
    if (!country) newErrors.country = "Country is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box>
      <Heading mb="15px">Shareholder(s) Information</Heading>

      {!native && (
        <YesNoField
          label={"Are there any changes in shareholders?"}
          setValue={(value) => handleYesNoChange(value, "ChangeShareholders")}
          enabled={enabled}
        />
      )}

      {(noChangeShareholders) && (
        <Text mt="12px !important">All set!</Text>
      )}

      {(yesChangeShareholders || enabled === true) && (
        <>

          {/* Officers and Shareholders Lists */}
          <Box>
            <HStack justify={"space-between"}>
              <Heading as="h3" color="blue.700" mt="24px !important">
                Current Shareholders:
              </Heading>
              <Button
                colorScheme="blue"
                variant="solid"
                leftIcon={<AddIcon />}
                onClick={handleShareholderAdd}
              >
                Add
              </Button>
            </HStack>
            {request.shareholders.currentShareholders &&
              request.shareholders.currentShareholders.length > 0 && (
                <Box my={4}>
                  <List
                    borderBottom="solid 1px gray"
                    ml={{base:"0", md:"10%"}}
                    w={{base:"100%", md:"60%"}}
                    color="blue.500"
                  >
                    {request.shareholders.currentShareholders.map(
                      (shareholder, index) => (
                        <ListItem key={shareholder.key}>
                          <HStack justify="space-between">
                            <Text width="60%" fontWeight="bold" mb="0 !important">
                              {shareholder.firstName} {shareholder.lastName}
                              {shareholder.corpName} {shareholder.corpAccessNumber}
                            </Text>
                            <HStack>
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() =>
                                  handleEditOfficerShareholder(shareholder, "shareholder")
                                }
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() =>
                                  handleDeleteShareholder(shareholder.key)
                                }
                              />
                            </HStack>
                          </HStack>
                        </ListItem>
                      )
                    )}
                  </List>

                  <Divider h="50px" />

                  <Heading as="h4">Changes</Heading>
                  {/* New Shareholders */}
                  <New request={request} setRequest={setRequest} />
                  {/* Deleted Shareholders */}
                  <Delete request={request} setRequest={setRequest} />
                </Box>
              )}
          </Box>
        </>
      )}
      <Modal isOpen={showModal} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable
              ? "Edit Shareholder"
              : request.shareholders.currentShareholders.length === 0
              ? "Current Shareholder"
              : "Add Shareholder"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {!isEditable && (
            <Center>
              <RadioGroup
                onChange={setModalShareholderType}
                value={modalShareholderType}
                mb="24px"
              >
                <HStack>
                  <Radio value='individual'>Individual</Radio>
                  <Radio value='corporation'>Corporation</Radio>
                </HStack>
              </RadioGroup>
            </Center>
            )}

            {((!isEditable && modalShareholderType === 'individual') || formData.firstName !== "") && (
            <>
            <FormControl isRequired isInvalid={errors.firstName}>
              <FormLabel>First Name:</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.firstName ? "red.500" : "gray.200"}
              />
              {errors.firstName && (
                <Text color="red.500">{errors.firstName}</Text>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={errors.lastName}>
              <FormLabel>Last Name:</FormLabel>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.lastName ? "red.500" : "gray.200"}
              />
              {errors.lastName && (
                <Text color="red.500">{errors.lastName}</Text>
              )}
            </FormControl>
            </>
            )}

            {((!isEditable && modalShareholderType === 'corporation') || formData.corpName !== "") && (
            <>
            <FormControl isRequired isInvalid={errors.corpName}>
              <FormLabel>Corporation Name:</FormLabel>
                <Input
                  name="corpName"
                  value={formData.corpName}
                  onChange={handleFormChange}
                  mb="4"
                  borderColor={errors.corpName ? "red.500" : "gray.200"}
                />
                {errors.corpName && (
                  <Text color="red.500">{errors.corpName}</Text>
                )}
            </FormControl>
            <FormControl isRequired isInvalid={errors.corpAccessNumber}>
              <FormLabel>Corporate Access Number:</FormLabel>
              <Input
                name="corpAccessNumber"
                value={formData.corpAccessNumber}
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.corpAccessNumber ? "red.500" : "gray.200"}
              />
              {errors.corpAccessNumber && (
                <Text color="red.500">{errors.corpAccessNumber}</Text>
              )}
            </FormControl>
            </>
            )}

            <FormControl isRequired isInvalid={errors.phone}>
              <FormLabel>Phone:</FormLabel>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.phone ? "red.500" : "gray.200"}
              />
              {errors.phone && <Text color="red.500">{errors.phone}</Text>}
            </FormControl>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>Email:</FormLabel>
              <Input
                placeholder=""
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                mb="4"
                borderColor={errors.email ? "red.500" : "gray.200"}
              />
              {errors.email && <Text color="red.500">{errors.email}</Text>}
            </FormControl>
            <FormControl>
              <Text fontWeight="bold">Residential Address (current):</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired isInvalid={errors.streetLine1}>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input
                        borderColor={errors.streetLine1 ? "red.500" : "orange"}
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                      {errors.streetLine1 && (
                        <Text color="red.500">{errors.streetLine1}</Text>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        borderRadius="md"
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired isInvalid={errors.city}>
                      <FormLabel>City</FormLabel>
                      <Input
                        borderColor={errors.city ? "red.500" : "orange"}
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                      {errors.city && (
                        <Text color="red.500">{errors.city}</Text>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired isInvalid={errors.postalCode}>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        borderColor={errors.postalCode ? "red.500" : "orange"}
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                      {errors.postalCode && (
                        <Text color="red.500">{errors.postalCode}</Text>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired isInvalid={errors.province}>
                      <FormLabel>Province</FormLabel>
                      <Input
                        borderColor={errors.province ? "red.500" : "orange"}
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                      {errors.province && (
                        <Text color="red.500">{errors.province}</Text>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired isInvalid={errors.country}>
                      <FormLabel>Country</FormLabel>
                      <Input
                        borderColor={errors.country ? "red.500" : "orange"}
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                      {errors.country && (
                        <Text color="red.500">{errors.country}</Text>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
            <br />
            <FormControl>
              <FormLabel>New Name (if applicable):</FormLabel>
              <Input
                name="newName"
                placeholder="New Name"
                value={formData.newName}
                onChange={handleFormChange}
                mb="4"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Shareholders;
