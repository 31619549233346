import React, { useEffect, useState } from "react";
import {
  Grid,
  Center,
  Flex,
  Heading,
  Button,
  Box,
  VStack,
  Image,
  ListItem,
  ListIcon,
  List,
  GridItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";

import BannerLine from "../utils/BannerLine.js";

const MainServices = () => {
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="home-main">

      <Flex
        justifyContent="center"
        mt={{base:"0", md:"40px"}}
      >
        <Grid
          className="container"
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
          }}
          gap={{base:8, md:16}}
          p="10px"
        >
          <GridItem>
            <Image
              src="/images/AI-photos/start-new-business.png"
            />
          </GridItem>
          <GridItem alignContent="center">
            <Heading as="h2" mb={4} color="black !important">
              Decide which registration is best for you:
            </Heading>
            <List mb={7} spacing={3}>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Corporation
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Trade Name / Sole Proprietorship
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Partnership (General / Limited)
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Extra-Provincial Registration
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Non-Profit / Societies
              </ListItem>
            </List>
            <Button
              className="btn-black"
              onClick={() => {
                navigate("/main");
              }}
            >
              Let's get started !
            </Button>
          </GridItem>
        </Grid>
      </Flex>
      
      <br /><br />
      
      <Flex justifyContent="center" pb="30px">
        <Grid
          className="container"
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
          }}
          gap={{base:8, md:16}}
          p="10px"
        >
          <GridItem alignContent="center" order={{ base: 2, md: 1 }}>
            <Heading as="h2" mb={4} color="black !important">
              Manage Your Business
            </Heading>
            <List mb={7} spacing={3}>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Amend Corporation
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Amend Partnership
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Cancel or Dissolve Corporation
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Annual Return
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Corporate Search
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Expanding Business into Alberta
              </ListItem>
            </List>
            <Button
              className="btn-black"
              colorScheme="blue"
              onClick={() => {
                navigate("/services");
              }}
            >
              Let's get started !
            </Button>
          </GridItem>
          <GridItem order={{ base: 1, md: 2 }}>
            <Image
              src="/images/AI-photos/manage-your-business.png"
            />
          </GridItem>
        </Grid>
      </Flex>
      
      {/* Buttons */}
      <Center pb="80px" pt="40px">
        <VStack w="100%">
          <Flex className="container" justifyContent="center">
            <Heading color="black !important">
              Already know what you need? Please choose from the following:
            </Heading>
          </Flex>
          <Grid
            className="container"
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={6}
            p={6}
            pb={0}
          >
            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/existingCorporate");
              }}
            >
              Amend Corporation
            </Button>
            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/existingPartnership/amendPartners");
              }}
            >
              Amend Partnership
            </Button>
            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/cancel-dissolve");
              }}
            >
              Cancel / Dissolve Corporation
            </Button>

            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/annualReturn");
              }}
            >
              Annual Return
            </Button>
            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/corpSearch");
              }}
            >
              Corporate Search
            </Button>
            <Button
              className={"btn-black"}
              size="lg"
              p="25px"
              fontSize="18px"
              onClick={() => {
                navigate("/continueInAB");
              }}
            >
              Expanding Business into Alberta
            </Button>
          </Grid>
        </VStack>
      </Center>
      {/* reminder banner */}
      <BannerLine
        line="Don't miss out on your annual returns."
        linkText="Sign up for e-reminders"
        link="/reminders"
      />

      {/* Services */}
      <Box p="10" mb="15" bgGradient="linear(to-br, teal.100, green.700)" textAlign="center">
        <VStack spacing={6}>
          <Heading color="black !important">Browse Through All Our Services</Heading>
          <Button
            className="btn-black"
            fontSize="20px"
            padding="32px"
            width="200px"
            onClick={() => navigate("/services")}
          >
            Services
          </Button>
        </VStack>

        <VStack spacing={6} mt={8}>
          <Heading color="black !important">Introducing <span class="snappy">Snappy Subscriptions</span> to Manage Your Business</Heading>
          <Button
            className="btn-black"
            fontSize="20px"
            padding="32px"
            width="200px"
            onClick={() => navigate("/help")}
          >
            Learn More...
          </Button>
        </VStack>
      </Box>

    </div>
  );
};

export default MainServices;
