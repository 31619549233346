import React from "react";
import {
  Text,
  Heading,
  Input,
  FormLabel,
  Grid,
  GridItem,
  FormControl
} from "@chakra-ui/react";
import Province from "../../../utils/Province";

const AccountInformation = ({ request, setRequest }) => {
  const handleChangeAccountAddress = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      accountInfo: {
        ...prevRequest.accountInfo,
        [field]: value,
      },
    }));
  };

  return (
    <>
      <Heading>Personal Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="20">
              My first name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.firstName}
              onChange={(e) => handleChangeAccountAddress("firstName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My last name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.lastName}
              onChange={(e) => handleChangeAccountAddress("lastName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My phone number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.phone}
              onChange={(e) => handleChangeAccountAddress("phone", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My email is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.email}
              onChange={(e) => handleChangeAccountAddress("email", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <br />

      <Text fontWeight="bold">My address is:</Text>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={3}
      >
        <GridItem>
          <FormLabel>Address Line 1</FormLabel>
          <Input
            value={request.accountInfo.streetLine1}
            onChange={(e) => handleChangeAccountAddress("streetLine1", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            value={request.accountInfo.streetLine2}
            onChange={(e) => handleChangeAccountAddress("streetLine2", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>City</FormLabel>
          <Input
            value={request.accountInfo.city}
            onChange={(e) => handleChangeAccountAddress("city", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Province</FormLabel>
          <Province
            value={request.accountInfo.province}
            onChange={(e) =>
              handleChangeAccountAddress("province", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          <FormLabel>Country</FormLabel>
          <Input
            value={request.accountInfo.country}
            onChange={(e) => handleChangeAccountAddress("country", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Postal Code</FormLabel>
          <Input
            value={request.accountInfo.postalCode}
            onChange={(e) => handleChangeAccountAddress("postalCode", e.target.value)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default AccountInformation;
