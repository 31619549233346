import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Button,
  HStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import NavBar from "../../../../utils/NavBar";
import Payment from "../../../new/register/Payment";
import PartnersInfo from "./Partners";
import AccountInformation from "./AccountInformation";
// import BusinessInformation from "./businessInformation";
import IncServInformation from "../changeAddress/BusinessInfo"
import Disclaimer from "../../../../utils/Disclaimer";
import { snappyCorpPriceList } from "../../../../services/price";
import Review from "../../../new/register/Review";

const AmendPartners = () => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [request, setRequest] = useState({
    serviceType: "ChangePartners",
    price: snappyCorpPriceList.ChangePartners,
    prices: {
      default: snappyCorpPriceList.ChangePartners,
      SubscribeAnnualMaintenance: {value: snappyCorpPriceList.SnappyAnnualSubscription, enabled: false}
    },
    accountInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      email: ""
    },
    businessInfo: {
      type: "",
      businessName: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "Alberta",
      country: "Canada",
      postalCode: ""
    },
    partners: {
      currentPartners: [],
      newPartners: [],
      deletedPartners: [],
      modifiedPartners: [],
      results: [],
    },
    yesno: {
      ChangeRegisteredAddress: { enabled: false },
      ChangePartners: { enabled: false }
    },
    annualMgmt: false,
    review: [
      'accountInfo',
      'businessInfo',
      'partners'
    ],
    reviewFieldCount: 0,
    reviewFieldNoDataCount: 0,
    reviewFieldNotRequired: {
      accountInfo: {
        streetLine2: false
      },
      businessInfo: {
        streetLine2: false
      }
    },
    reviewFieldHidden: {
      businessInfo: {
        type: false
      },
      partners: {
        result: false,
        results: false, 
        modifiedPartners: false
      }
    },
    toggleYesNo: (value, type) => {
      setRequest((prevRequest) => {
        let bool = value === "Yes" ? true : false;
        let updatedYesNo = prevRequest.yesno;

        updatedYesNo[type].enabled = bool;

        return {
          ...prevRequest,
          yesno: updatedYesNo
        };
      });
    },
    togglePrice: (value, priceType) => {
      setRequest((prevRequest) => {
        let bool = value === 'Yes' ? true : false;
        let updatedPrices = prevRequest.prices;
  
        updatedPrices[priceType].enabled = bool;
  
        return {
          ...prevRequest,
          prices: updatedPrices
        };
      });

      request.updatePrice();
    },
    updatePrice: () => {
      setRequest((prevRequest) => {
        let updatedPrice = 0;
  
        updatedPrice += prevRequest.prices.default;
  
        for (const key in prevRequest.prices){
          if (prevRequest.prices[key].enabled === true){
            updatedPrice += prevRequest.prices[key].value;
          }
        }
  
        return {
          ...prevRequest,
          price: updatedPrice
        };
      });
    }
  });

  //   // Define sections for each company type
  const sectionsMap = [
    "accountInformation",
    "businessInformation",
    "partners",
    "review",
    "payment",
  ];

  const handlePrevious = () => {
    setSelectedSectionIndex((prevIndex) => Math.max(0, prevIndex - 1));
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setSelectedSectionIndex((prevIndex) =>
      Math.min(prevIndex + 1, sectionsMap.length - 1)
    );
    window.scrollTo(0, 0);
  };

  const renderMainComponent = () => {
    const sections = sectionsMap;
    const selectedSection = sections[selectedSectionIndex];
    switch (selectedSection) {
      case "accountInformation":
        return <AccountInformation request={request} setRequest={setRequest} />;
      case "businessInformation":
        return <IncServInformation request={request} setRequest={setRequest} />;
      case "partners":
        return <PartnersInfo request={request} setRequest={setRequest} />;
      case "review":
        return <Review request={request} setRequest={setRequest} handleNext={handleNext} />;
      case "payment":
        return <Payment request={request} setRequest={setRequest} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar />

      <Center mt="40px">
        <VStack alignItems="flex-start" className="container">
          <HStack w="90%" justifyContent="min-content">
            <Heading as="h1">Changes in Partnership</Heading>
          </HStack>

          <Divider className="divider" />

          <Flex w="100%">
            {/* Sidebar */}
            <Box
              className="sidebar"
              w="25%"
            >
              <VStack align="start">
                {sectionsMap?.map((section, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelectedSectionIndex(index)}
                    variant={selectedSectionIndex === index ? "solid" : "ghost"}
                    bg={selectedSectionIndex === index ? "green.700" : ""}
                    w="100%"
                    color={selectedSectionIndex === index ? "white" : ""}
                    m={0}
                    _hover={{
                      bg:
                        selectedSectionIndex === index
                          ? "green.700"
                          : "green.400",
                      color: "white", // Set text color for better contrast
                    }}
                  >
                    {section
                      .replace("account", "personal")
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  </Button>
                ))}
              </VStack>
            </Box>

            {/* Main bar */}

            <Box w="75%" className="maincomponent">
              {renderMainComponent()}
              {/* Previous and Next Button */}

              <Flex className="nextprevious">
                <Center width="100%">
                  {selectedSectionIndex !== 0 && (
                    <Button
                      className="btn-black"
                      leftIcon={<ArrowLeftIcon />}
                      onClick={handlePrevious}
                      aria-label="Previous"
                      mr={2}
                      disabled={selectedSectionIndex === 0 || !sectionsMap}
                    >
                      Previous
                    </Button>
                  )}
                  {selectedSectionIndex < sectionsMap.length - 1 && (
                    <Button
                      className="btn-black"
                      rightIcon={<ArrowRightIcon />}
                      onClick={handleNext}
                      aria-label="Next"
                      disabled={
                        selectedSectionIndex === sectionsMap?.length - 1 ||
                        !sectionsMap
                      }
                    >
                      Next
                    </Button>
                  )}
                </Center>
              </Flex>

            </Box>
          </Flex>
        </VStack>
      </Center>

      <Disclaimer />
    </>
  );
};

export default AmendPartners;
