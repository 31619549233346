import React from "react";
import {
  Select
} from "@chakra-ui/react";

const Province = ({
  value,
  onChange,
  width
}) => {
  
  const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
  ];
  
  return (
    <Select
      width={width || { base: "100%", md: "30ch" }}
      value={value}
      onChange={onChange}
    >
      {provinces.map((province, index) => (
        <option key={index} value={province}>
          {province}
        </option>
      ))}
    </Select>
  );
};

export default Province;
