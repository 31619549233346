import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Center
} from "@chakra-ui/react";

const UnderConstruction = () => {

  return (<>
    <Flex
      className="underconstruction"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bg="black"
      color="white"
      width="100%"
      height="100vh"
    >
      <Box>
        <Center>
          <Image
            className="logo"
            alt="Snappy Corporations logo"
            title="Snappy Corporations logo"
            src="/images/logo.png"
            boxShadow="0 !important"
            position="static !important"
            width={{base:"250px", lg:"350px"}}
          />
        </Center>

        <Box
          class="icon"
          fontSize="5rem"
          color="#ff6347"
          mb="20px"
        >
          🚧
        </Box>

        <Heading as="h1" color="white">
          We’ll be back soon! :-)
        </Heading>

        <Heading as="h2" fontSize="20px !important" color="#666">
          Our website is currently undergoing scheduled maintenance... thank you for your patience.
        </Heading>

        <Text>
          If you have any questions, comments, or concerns please contact us at:
          <br />
          <b>+1 (403) 512 - 8777</b>
        </Text>
      </Box>
    </Flex>
  </>
  );
};

export default UnderConstruction;
