import React from "react";

import {
  Box,
  FormControl,
  Heading,
  Input,
  HStack,
  Select,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
const IncServInformation = ({ request, setRequest }) => {
  const handleChangeBusinessInfo = (field, value) => {
    let price = request.prices.default;

    if (field === "province") {
      if (value === "Alberta") price = 20;
      else if (value === "British Columbia") price = 35.52;
      else if (value === "Manitoba") price = 30;
      else if (value === "New Brunswick") price = 25;
      else if (value === "Newfoundland and Labrador") price = 15.75;
      else if (value === "Northwest Territories") price = 29;
      else if (value === "Nova Scotia") price = 28.42;
      else if (value === "Nunavut") price = 20;
      else if (value === "Prince Edward Island") price = 20;
      else if (value === "Quebec") price = 20;
      else if (value === "Federal (Canada)") price = 20;
      else if (value === "Saskatchewan") price = 35.05;
      else if (value === "Yukon") price = 40;
      else if (value === "Ontario") {
        if (request.businessInfo.type === "Corporation") {
          price = 92.0;
        } else if (request.businessInfo.type === "Trade Name / Partnership") {
          price = 49.43;
        }
      }
    } else if (field === "type") {
      if (request.businessInfo.province === "Ontario") {
        if (value === "Corporation") {
          price = 92.0;
        } else if (value === "Trade Name / Partnership") {
          price = 49.43;
        }
      }
    }

    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
      prices: {
        ...prevRequest.prices,
        default: price
      }
    }));

    request.updatePrice();
  };
  
  const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
    "Federal (Canada)",
  ];

  return (
    <>
      <Heading mb="15px">Business Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel>Business name</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.businessName}
              onChange={(e) =>
                handleChangeBusinessInfo("businessName", e.target.value)
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel>Province</FormLabel>
          </GridItem>
          <GridItem>
            <HStack>
              <Select
                w={{ base: "100%", md: "30ch" }}
                value={request.businessInfo.province}
                onChange={(e) =>
                  handleChangeBusinessInfo("province", e.target.value)
                }
              >
                {provinces.map((province, index) => (
                  <option key={index} value={province}>
                    {province}
                  </option>
                ))}
              </Select>
              <FormLabel>
                <span className="price">{`$${request.prices.default.toFixed(
                  2
                )} CAD`}</span>
              </FormLabel>
            </HStack>
          </GridItem>

          <GridItem>
            <FormLabel>Business type</FormLabel>
          </GridItem>
          <GridItem>
            <Select
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.type}
              onChange={(e) => handleChangeBusinessInfo("type", e.target.value)}
            >
              <option>Trade Name / Partnership</option>
              <option>Corporation</option>
            </Select>
          </GridItem>

          <GridItem>
            <FormLabel>Corporate Access Number is (optional)</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.corporateAccessNumber}
              onChange={(e) =>
                handleChangeBusinessInfo(
                  "corporateAccessNumber",
                  e.target.value
                )
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel>Historical date (optional)</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              type="date"
              placeholder="yyyy-mm-dd"
              value={request.businessInfo.historicalDate}
              onChange={(e) =>
                handleChangeBusinessInfo("historicalDate", e.target.value)
              }
            />
          </GridItem>
        </Grid>
      </FormControl>

    </>
  );
};
export default IncServInformation;
