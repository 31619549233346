import React, { useState } from "react";
import {
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import { useNavigate } from "react-router-dom";
import { handleSendInquiryEmail } from "../../../services/email.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";
const Amalgamate = () => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail =async () => {
    (await handleSendInquiryEmail(emailData))
      
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/services");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Amalgamate Corporations</Heading>

          <Heading as="h2">
            Things to know before amalgamating corporations:
          </Heading>

          <List>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              You will be permitted to conduct business under a single name (either a new name can be chosen or you can keep the same name as one of the amalgamating companies).
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Both the corporations will be working together under one name moving forward as a single corporation.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Once the corporations are amalgamated, you only have to take care of one corporation instead of many.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              The liability for all obligations and liabilities of the business shall be shifted to the new corporation. Please speak to a lawyer for more details and to clarify any questions you may have.
            </ListItem>
          </List>

          <Button colorScheme="green" onClick={onOpen}>
            Contact us directly
          </Button>

          <Divider height="2vh" />
          {/* <>
          <Heading as ="h2">Other Options</Heading>

          <Text>You can choose to register a partnership.</Text>

          <List>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" mr="6" />
              Limit your liability.
            </ListItem>
          </List>

          <Flex width="100%" justifyContent="center">
            <VStack>
              <Button
                colorScheme="blue"
                bgColor="blue.500"
                onClick={() => {
                  navigate("/main/corp-type");
                }}
                fontSize="3xl"
                p="9"
                mt="24px"
                mb="24px"
              >
                Switch to Partnership !
              </Button>

              <Heading as="h5" fontSize="140%" mb="24px">
                OR
              </Heading>

              <Text as="h5" fontSize="140%" mb="24px">
                Choose a <b>Limited Liability Partnership</b>, you may need to have a &nbsp;
                <Link
                  href="https://www.lawsociety.ab.ca/lawyers-and-students/membership-services/professional-corporations/"
                  style={{ color: "#03a9f4", textDecoration: "underline" }}
                >
                  Professional Corporation
                </Link>
                .
              </Text>
            </VStack>
          </Flex>
            
</> */}
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Amalgamate;
