import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import StartPage from "./StartPage";
import BusinessStructure from "./new/BusinessStructure";
import ExtraProvincial from "./new/other/ExtraProvincial";
import NonProfitSocieties from "./new/other/NonProfitSocieties";
import RegisteredOfficeInAB from "./new/other/RegisteredOfficeInAB";
import IncType from "./new/other/IncType";
import RouteNotFound from "../utils/RouteNotFound";
import NewPartnership from "./checklist/NewPartnership";
import NewTradeName from "./checklist/NewTradeName";
import BusinessType from "./new/other/BusinessType";
import NewExtraprovincialRegister from "./new/register/extraProvincial/Extraprovincial";
import Services from "../home/services/Services";

const Main = () => {
  const [request, setRequest] = useState({
    disclaimerClicked: false,
  });

  return (
    <Routes>
      <Route
        path="/"
        element={<StartPage request={request} setRequest={setRequest} />}
      />
      {/* link to business type / structure */}
      <Route path="/business" element={<BusinessType />} />

      {/* link to checklist */}
      <Route
        path="/location/extra-provincial"
        element={<ExtraProvincial request={request} setRequest={setRequest} />}
      />
      <Route
        path="/location/registered-office"
        element={<RegisteredOfficeInAB />}
      />

      <Route path="/business-type" element={<BusinessStructure />} />
      <Route path="/existing-business" element={<Services />} />

      {/* after selecting Incorporation in business type
      asking for name or numbered corporation */}
      <Route path="/corp-type" element={<IncType />} />

      {/* <Route path="/new/corp/number" element={<NewNumberedCorp />} />
      <Route path="/new/corp/name" element={<NewNamedCorp />} /> */}
      <Route path="/new/partnership" element={<NewPartnership />} />
      <Route path="/new/trade-name" element={<NewTradeName />} />
      <Route path="/new/extra-provincial" element={<NewExtraprovincialRegister />} />
      <Route path="/new/nonprofit-societies" element={<NonProfitSocieties />} />

      {/* <Route path="/new/guest/buy-now" element={<BuyNowDocumentsPage />} /> */}

      <Route path="/*" element={<RouteNotFound />} />
    </Routes>
  );
};

export default Main;
