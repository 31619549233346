import React from "react";
import {
  Heading,
  Button,
  Input,
  Flex,
  Text,
  Grid,
  GridItem,
  HStack,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import InfoButton from "../../../../utils/Popover";
import Province from "../../../../utils/Province";

const AgentForService = ({ request, setRequest }) => {
  const formData = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "Calgary",
    province: "Alberta",
    country: "Canada",
    postalCode: ""
  };

  const handleFormChange = (field, value) => {
    setRequest((prevData) => ({
      ...prevData,
      agent: {
        ...prevData.agent,
        [field]: value,
      },
    }));
  };

  const handleYes = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      agent: "Snappy Corp",
    }));

    request.togglePrice("Yes", "AgentForService");
  };

  const handleNo = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      agent: formData,
    }));

    request.togglePrice("No", "AgentForService");
  };

  return (
    <>
      <Heading>Agent for Service</Heading>

      <Text>
        Your corporation's agent for service is a contact person, <b>an individual, with a physical and mailing address in Alberta.</b> The agent for service does not have to be a lawyer or solicitor. A notice or document to be sent or delivered to the corporation company may be sent or delivered to the agent.
      </Text>

      <HStack justify="space-between">
        <Heading as="h3" mt="24px !important">
          Would you like Snappy Corporations to be your agent for service in Alberta?
        </Heading>
        <HStack alignItems="baseline">
          <InfoButton info="It is required by Alberta bylaws to have an agent for service." />
          <Text className="price">
            +${request.prices.AgentForService.value}
          </Text>
        </HStack>
      </HStack>

      <Text>
        We can be the point of contact for all communications relating to your corporation.
      </Text>

      <Flex mt={4}>
        <Button
          colorScheme={request.agent === "Snappy Corp" ? "green" : "yellow"}
          mr={2}
          onClick={handleYes}
        >
          Yes
        </Button>
        <Button
          colorScheme={request.agent === "Snappy Corp" ? "yellow" : "green"}
          onClick={handleNo}
        >
          No
        </Button>
      </Flex>

      <br />

      {request.agent === "Snappy Corp" && (
        <Text color="green.500" fontWeight="bold">
          We are happy to be your agent for service!
        </Text>
      )}

      {request.agent !== "Snappy Corp" && request.agent !== "" && (
        <>
          <Text fontWeight="bold">
            Please provide the following details for your agent for service:
          </Text>

          <FormControl>
            <Grid
              templateColumns={{ base: "1fr", md: "auto 1fr" }}
              gap={3}
              alignItems="center"
            >
              <GridItem>
                <FormLabel pr="20">
                  Agent's first name is
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  w={{ base: "100%", md: "30ch" }}
                  value={request.agent.firstName}
                  onChange={(e) =>
                    handleFormChange("firstName", e.target.value)
                  }
                />
              </GridItem>

              <GridItem>
                <FormLabel pr="20">
                  Agent's last name is
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  w={{ base: "100%", md: "30ch" }}
                  value={request.agent.lastName}
                  onChange={(e) => handleFormChange("lastName", e.target.value)}
                />
              </GridItem>

              <GridItem>
                <FormLabel pr="20">
                  Agent's email is
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  w={{ base: "100%", md: "30ch" }}
                  value={request.agent.email}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </GridItem>
            </Grid>
          </FormControl>

          <Text mt="24px !important">
            Agent's address is:
            <br />
            (must be an Alberta address)
          </Text>

          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={3}
          >
            <GridItem>
              <FormLabel>Address Line 1</FormLabel>
              <Input
                value={request.agent.streetLine1}
                onChange={(e) =>
                  handleFormChange("streetLine1", e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                value={request.agent.streetLine2}
                onChange={(e) =>
                  handleFormChange("streetLine2", e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>City</FormLabel>
              <Input
                value={request.agent.city}
                onChange={(e) => handleFormChange("city", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Province</FormLabel>
              <Province
                value={request.agent.province}
                onChange={(e) =>
                  handleFormChange("province", e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Country</FormLabel>
              <Input
                value={request.agent.country}
                onChange={(e) => handleFormChange("country", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Postal Code</FormLabel>
              <Input
                value={request.agent.postalCode}
                onChange={(e) => handleFormChange("postalCode", e.target.value)}
              />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};

export default AgentForService;
