import { useState } from "react";
import {
  Heading,
  Table,
  Tbody,
  Th,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Text,
  Button,
  FormControl,
  FormLabel,
  Divider,
  IconButton,
  Grid,
  GridItem
} from "@chakra-ui/react";

import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import YesNoField from "../../../../utils/YesNoField";

const ShareHolders = ({ request, setRequest }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    percentage: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
    isManager: false,
    isOfficer: false,
    // rights: {
    //   vote: true,
    //   dividends: true,
    //   somethingElse: true,
    // },
  });

  const [decideSharesLater, setdecideSharesLater] = useState("");

  const [isSum100, setIsSum100] = useState(false);

  const handlePriceChange = (value, priceType) => {
    request.togglePrice(value, priceType);

    if (value === "Yes") {
      setdecideSharesLater(false);
    } else {
      setdecideSharesLater(true);
    }
  };

  const enabled = request.prices.RegisterShareholders.enabled;

  const handleShareholderAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      percentage: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      province: "",
      country: "",
      isManager: false,
      isOfficer: false,
      // rights: {
      //   vote: true,
      //   dividends: true,
      //   somethingElse: true,
      // },
    });
    setShowModal(true);
  };

  const handleSubmit = () => {
    // Check if all required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    // If it's editable, update the existing shareholder, otherwise append new formData.
    setRequest((prevRequest) => {
      // console.log('prevRequest', prevRequest);
      // console.log('request', request);
      // return false;

      // Constructing updated shareholder inside setRequest to capture the current state correctly
      const updatedShareholders = isEditable
        ? prevRequest.shareholders.currentShareholders.map((shareholder) =>
            shareholder.key === formData.key
              ? { ...shareholder, ...formData }
              : shareholder
          )
        : [...prevRequest.shareholders.currentShareholders, formData];

      return {
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          currentShareholders: updatedShareholders
        }
      };
    });

    setShowModal(false);
    setIsEditable(false);

    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      percentage: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      province: "",
      country: "",
      // rights: {
      //   vote: true,
      //   dividends: true,
      //   somethingElse: true,
      // },
    });
  };

  const handleEdit = (shareholder) => {
    setFormData({ ...shareholder });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDelete = (key) => {
    if (window.confirm("Are you sure you want to delete this shareholder?")) {
      const updatedShareholders = request.shareholders.currentShareholders.filter(
        (shareholder) => shareholder.key !== key
      );

      // console.log('request.shareholders', request.shareholders);
      // console.log('updatedShareholders', updatedShareholders);

      setRequest((prevRequest) => ({
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          currentShareholders: updatedShareholders
        }
      }));
    }
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  };

  // const handleFormChange = (field, value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [field]: value,
  //   }));
  // };

  const handleGridChange = (key, e) => {
    const value  = e.target.value;
    let sum = 0;
    let isZero = false;

    setRequest((prevRequest) => {
      const updatedShareholders = (function () {
        let newarr = [];

        for (let i = 0; i < prevRequest.shareholders.currentShareholders.length; i++) {
          let temp = prevRequest.shareholders.currentShareholders[i];
          if (temp.key === key) {
            temp.percentage = value;
          }

          if (temp.percentage === 0 || temp.percentage === "") {
            isZero = true;
          }

          newarr[i] = temp;

          sum += parseInt(temp.percentage);
        }

        return newarr;
      })();
      // console.log('sum', sum);
      sum === 100 && !isZero ? setIsSum100(true) : setIsSum100(false);
      sum = 0;
      // console.log('updatedShareholders', updatedShareholders);

      return {
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          currentShareholders: updatedShareholders
        }
      };
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // console.log("e", e);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   setRequest((prevRequest) => ({
  //     ...prevRequest,
  //     shareholders: [
  //       {
  //         ...request.accountInfo,
  //         percentage: 100,
  //       },
  //     ],
  //   }));
  // }, []);

  return (
    <>
      <Heading>Shareholders</Heading>

      <YesNoField
        label="You may register your shareholders at this time or may do so while filing your first annual return."
        text="You have to file your annual returns each year to keep your company alive. It costs $80 CAD."
        setValue={(value) => handlePriceChange(value, "RegisterShareholders")}
        enabled={request.prices.RegisterShareholders.enabled}
        price={request.prices.RegisterShareholders.value}
      />

      {(decideSharesLater === true || enabled === false) && (
        <Text color="green.500" fontWeight="bold" mt="24px !important">
          You may decide how you would like to register shareholders{" "}
          <i>prior</i> to filing your first annual return.
        </Text>
      )}
      {(decideSharesLater === false || enabled === true) && (
        <>
          <Divider h="24px" />
          <Table width="100%">
            <Tbody>
              <Tr>
                <Th width="50%">Name</Th>
                <Th>% of voting shares</Th>
                {/* <Th>Rights</Th> */}
                <Th width="200px">Actions</Th>
              </Tr>

              {request.shareholders.currentShareholders &&
                request.shareholders.currentShareholders.length > 0 &&
                request.shareholders.currentShareholders.map((shareholder, index) => (
                  <Tr key={shareholder.key}>
                    <Td color="blue.500" fontWeight="bold">
                      {shareholder.firstName} {shareholder.lastName}
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        textAlign="right"
                        name="percentage"
                        value={shareholder.percentage}
                        onChange={(e) => handleGridChange(shareholder.key, e)}
                      />
                    </Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        onClick={() => handleEdit(shareholder)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleDelete(shareholder.key)}
                      />
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          <Button
            colorScheme="blue"
            mt="12px"
            leftIcon={<AddIcon />}
            onClick={handleShareholderAdd}
          >
            Add Shareholder
          </Button>

          <Divider h="30px" />

          <Heading as="h3">Requirements</Heading>
          <Text fontWeight="bold" color={isSum100 ? "green.500" : "red"}>
            Ownership of shares must equal 100%
          </Text>
        </>
      )}
      <Modal isOpen={showModal} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable ? "Edit Shareholder" : "Add Shareholder"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>First Name:</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Last Name:</FormLabel>
              <Input
                placeholder="Last Name / Corporate Name (full)"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                mb="4"
              />
              <Text fontWeight="bold">Residential Address:</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input                      
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Province</FormLabel>
                      <Input
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareHolders;
