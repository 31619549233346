import React, {  useState } from "react";
import {
  Heading,
  Button,
  Input,
  Flex,
  Text,
  FormLabel,
  Grid,
  GridItem,
  Divider,
  HStack,
  FormControl,
} from "@chakra-ui/react";
import InfoButton from "../../../../utils/Popover";
import YesNoField from "../../../../utils/YesNoField";

const AgentForService = ({ request, setRequest, native }) => {
  const formData = {
    firstName: "",
    lastName: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "Calgary",
    postalCode: "",
    province: "Alberta",
    country: "Canada",
  };

  const [yesChangeClicked, setYesChangeClicked] = useState(native);
  const [yesNURClicked, setYesNURClicked] = useState(false);
  const [noNURClicked, setNoNURClicked] = useState(false);

  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, "ChangeAgentForService");

    if (value === "Yes") {
      setYesChangeClicked(true);
    } else {
      setYesChangeClicked(false);
      setNoNURClicked(true);

      setRequest((prevRequest) => ({
        ...prevRequest,
        agent: "",
      }));

      request.togglePrice("No", "ChangeAgentForService");
    }
  };

  const handleYesNURClick = () => {
    setYesNURClicked(true);
    setNoNURClicked(false);

    setRequest((prevRequest) => ({
      ...prevRequest,
      agent: "Snappy Corp",
    }));

    request.togglePrice("Yes", "ChangeAgentForService");
  };

  const handleNoNURClick = () => {
    setYesNURClicked(false);
    setNoNURClicked(true);

    setRequest((prevRequest) => ({
      ...prevRequest,
      agent: formData,
    }));

    request.togglePrice("No", "ChangeAgentForService");
  };

  const handleFormChange = (field, value) => {
    setRequest((prevData) => ({
      ...prevData,
      agent: {
        ...prevData.agent,
        [field]: value,
      },
    }));
  };

  const changeEnabled = request.yesno.ChangeAgentForService.enabled;
  const nurEnabled = request.prices.ChangeAgentForService.enabled;

  return (
    <>
      <Heading mb="5">Agent for Service</Heading>

      <Text>
        Your corporation's agent for service is a contact person,{" "}
        <b>an individual, with a physical and mailing address in Alberta.</b>{" "}
        The agent for service does not have to be a lawyer or solicitor. A
        notice or document to be sent or delivered to the corporation/company
        may be sent or delivered to the agent.
      </Text>

      {!native && (
        <YesNoField
          label={"Do you want to change your agent for service?"}
          setValue={(value) =>
            handleYesNoChange(value, "ChangeAgentForService")
          }
          enabled={changeEnabled}
        />
      )}
      {/* {(noChangeClicked || changeEnabled === false) && (<Text mt="12px !important">All set!</Text>)} */}

      {(yesChangeClicked || changeEnabled === true) && (
        <>
          <HStack justify="space-between" align="center" mt="24px !important">
            <Heading as="h3" mt="24px !important">
              Would you like Snappy Corporations to be your agent for Service in
              Alberta?
            </Heading>
            <HStack alignItems="baseline">
              <InfoButton info="It is required by Alberta bylaws to have an agent for service." />
              <Text className="price">
                +${request.prices.ChangeAgentForService.value}
              </Text>
            </HStack>
          </HStack>

          <Text>
            We can be the point of contact for all communications relating to
            your corporation.
          </Text>

          <Flex mt={4}>
            <Button
              colorScheme={
                yesNURClicked || nurEnabled === true ? "green" : "yellow"
              }
              mr={2}
              onClick={handleYesNURClick}
            >
              Yes
            </Button>
            <Button
              colorScheme={
                noNURClicked || nurEnabled === false ? "green" : "yellow"
              }
              onClick={handleNoNURClick}
            >
              No
            </Button>
          </Flex>

          <Divider h="30px" />

          {((yesChangeClicked === true && yesNURClicked === true) ||
            (changeEnabled === true && nurEnabled === true)) && (
            <Text fontSize="bold" color="green.500" fontWeight="bold">
              We are happy to be your agent for service!
            </Text>
          )}

          {((yesChangeClicked === true && noNURClicked === true) ||
            (changeEnabled === true && nurEnabled === false) ||
            nurEnabled === false) && (
            <>
              <Text fontWeight="bold">
                Please provide the following details for your agent for service:
              </Text>

              <FormControl>
                <Grid
                  templateColumns={{ base: '1fr', md: 'auto 1fr' }}
                  gap={3}
                  alignItems="center"
                >
                  <GridItem>
                    <FormLabel pr="20">
                      My first name is
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      w={{ base: "100%", md: "30ch" }}
                      value={request.agent.firstName}
                      onChange={(e) =>
                        handleFormChange("firstName", e.target.value)
                      }
                      autofocus
                    />
                  </GridItem>

                  <GridItem>
                    <FormLabel pr="20">
                      My last name is
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      w={{ base: "100%", md: "30ch" }}
                      value={request.agent.lastName}
                      onChange={(e) =>
                        handleFormChange("lastName", e.target.value)
                      }
                      autofocus
                    />
                  </GridItem>

                  <GridItem>
                    <FormLabel pr="20">
                      My email is
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      w={{ base: "100%", md: "30ch" }}
                      value={request.agent.email}
                      onChange={(e) =>
                        handleFormChange("email", e.target.value)
                      }
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <Text mt="24px !important">
                Agent's address is:
                <br />
                (must be an Alberta address)
              </Text>

              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={3}
              >
                <GridItem>
                  <FormLabel>Address Line 1</FormLabel>
                  <Input
                    value={request.agent.streetLine1}
                    onChange={(e) =>
                      handleFormChange("streetLine1", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormLabel>Address Line 2</FormLabel>
                  <Input
                    value={request.agent.streetLine2}
                    onChange={(e) =>
                      handleFormChange("streetLine2", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormLabel>City</FormLabel>
                  <Input
                    value={request.agent.city}
                    onChange={(e) => handleFormChange("city", e.target.value)}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel>Province</FormLabel>
                  <Input
                    value={request.agent.province}
                    onChange={(e) =>
                      handleFormChange("province", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormLabel>Country</FormLabel>
                  <Input
                    value={request.agent.country}
                    onChange={(e) =>
                      handleFormChange("country", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormLabel>Postal Code</FormLabel>
                  <Input
                    value={request.agent.postalCode}
                    onChange={(e) =>
                      handleFormChange("postalCode", e.target.value)
                    }
                  />
                </GridItem>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AgentForService;
