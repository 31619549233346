import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  Input,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState("Select");

  useEffect(() => {
    const data =
      request.directors.currentDirectors &&
      request.directors.currentDirectors.map(
        (director) => `${director.lastName}, ${director.firstName}`
      );
    setList(data);
  }, [request.directors.currentDirectors]);

  const handleRemoveDirector = () => {
    let deletedDirector = null;
    if (selectedDirector && selectedDirector !== "Select") {
      if (selectedDirector.includes(", ")) {
        const [lastName, firstName] = selectedDirector.split(", ");
        deletedDirector = request.directors.currentDirectors.find(
          (director) =>
            director.firstName === firstName && director.lastName === lastName
        );
      } else
        deletedDirector = request.directors.currentDirectors.find(
          (director) =>
            director.firstName === selectedDirector ||
            director.lastName === selectedDirector
        );
      if (deletedDirector) {
        setRequest((prevRequest) => ({
          ...prevRequest,
          directors: {
            ...prevRequest.directors,

            deletedDirectors: [
              ...prevRequest.directors.deletedDirectors,
              {...deletedDirector,revokeDate:new Date().toISOString().split("T")[0] },
            ],
          },
        }));
        setList(
          list.filter(
            (director) =>
              director !==
              `${deletedDirector.lastName}, ${deletedDirector.firstName}`
          )
        );
      }

      setSelectedDirector(Select);
    }
  };

  const handleRestoreDirector = (index) => {
    const restoredDirector = request.directors.deletedDirectors[index];
    setRequest((prevRequest) => ({
      ...prevRequest,
      directors: {
        ...prevRequest.directors,
        deletedDirectors: prevRequest.directors.deletedDirectors.filter(
          (_, idx) => idx !== index
        ),
      },
    }));
    setList((prevList) => [
      ...prevList,
      `${restoredDirector.lastName}, ${restoredDirector.firstName}`,
    ]);

    setSelectedDirector(Select);
  };
  const handleRevokeDateChange = (index, newDate) => {
    setRequest((prevRequest) => {
      const updatedDeletedDirectors = [
        ...prevRequest.directors.deletedDirectors,
      ];
      updatedDeletedDirectors[index].revokeDate = newDate;

      return {
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          deletedDirectors: updatedDeletedDirectors,
        },
      };
    });
  };

  return (
    <>
      {console.log(request.directors)}
      <Heading as="h4" color="red.800">
        Removed Directors
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Director to be removed:
          </FormLabel>
        </GridItem>
        <Grid>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedDirector}
            defaultValue="Select"
            onChange={(e) => setSelectedDirector(e.target.value)}
          >
            <option>Select</option>
            {list.map((director, index) => (
              <option key={index}>{director}</option>
            ))}
          </Select>
        </Grid>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemoveDirector}
            disabled={!selectedDirector}
            aria-label="Remove Director"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Directors that are being <i>deleted</i> are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.directors.deletedDirectors &&
            request.directors.deletedDirectors.map((director, index) => (
              <ListItem key={index}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {director.firstName} {director.lastName}
                  </Text>
                  <Input type="date" width="150px" value={director.revokeDate} onChange={(e)=>{handleRevokeDateChange(index, e.target.value)}}/>
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleRestoreDirector(index)}
                    aria-label="Restore Director"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
