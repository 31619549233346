import React from "react";

import {
  Heading,
  Input,
  Text,
  Divider,
  FormLabel,
  FormControl,
  Grid,
  GridItem
} from "@chakra-ui/react";
import Province from "../../../../utils/Province";
const ExtraBusInformation = ({ request, setRequest }) => {
  const handleChangeBusinessInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
    }));
  };

  // const handleSuffixChange = (preference, suffix) => {
  //   const currentValue = request.businessInfo[preference] || "";
  //   handleChangeBusinessInfo(preference, `${currentValue} ${suffix}`);
  // };

  return (
    <>
      <Heading mb="15px">Business Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="5">
              My company's email address is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              placeholder="Hotels, Barber, Construction, Real Estate Listing, Agriculture etc"
              value={request.businessInfo.email}
              onChange={(e) => handleChangeBusinessInfo("email", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>
      
      <Text mt="24px !important"> 
        The street address of its registered office in Alberta will be:
      </Text>

      <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={3}
          >
            <GridItem>
              <FormLabel>Address Line 1</FormLabel>
              <Input
                value={request.businessInfo.streetLine1}
                onChange={(e) => handleChangeBusinessInfo("streetLine1", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                value={request.businessInfo.streetLine2}
                onChange={(e) => handleChangeBusinessInfo("streetLine2", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>City</FormLabel>
              <Input
                value={request.businessInfo.city}
                onChange={(e) => handleChangeBusinessInfo("city", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Province</FormLabel>
              <Province
                value={request.businessInfo.province}
                onChange={(e) =>
                  handleChangeBusinessInfo("province", e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Country</FormLabel>
              <Input
                value={request.businessInfo.country}
                onChange={(e) => handleChangeBusinessInfo("country", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Postal Code</FormLabel>
              <Input
                value={request.businessInfo.postalCode}
                onChange={(e) => handleChangeBusinessInfo("postalCode", e.target.value)}
              />
            </GridItem>
          </Grid>

      {/* <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={4}
      >
        <GridItem>
          {request.businessInfo.streetLine1 && (
            <Box as="label" fontSize="12px" mt="-10px" bg="white">
              Street Line 1
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="Street Line 1"
            value={request.businessInfo.streetLine1}
            onChange={(e) =>
              handleChangeBusinessInfo("streetLine1", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          {request.businessInfo.streetLine2 && (
            <Box as="label" fontSize="12px" mt="-10px" bg="white">
              Street Line 2
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="Street Line 2"
            value={request.businessInfo.streetLine2}
            onChange={(e) =>
              handleChangeBusinessInfo("streetLine2", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          {request.businessInfo.city && (
            <Box as="label" fontSize="12px" mt="-10px" bg="white">
              City
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="City"
            value={request.businessInfo.city}
            onChange={(e) => handleChangeBusinessInfo("city", e.target.value)}
          />
        </GridItem>
        <GridItem>
          {request.businessInfo.province && (
            <Box as="label" fontSize="12px" mt="-10px" bg="white">
              Province
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="Province"
            value={request.businessInfo.province}
            onChange={(e) =>
              handleChangeBusinessInfo("province", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          {request.businessInfo.country && (
            <Box as="label" fontSize="12px" mt="-10px" bg="white">
              Country
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="Country"
            value={request.businessInfo.country}
            onChange={(e) =>
              handleChangeBusinessInfo("country", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          {request.businessInfo.postalCode && (
            <Box as="label" fontSize="5px" mt="-10px" bg="white">
              Postal Code
            </Box>
          )}
          <Input
            variant="outline"
            borderColor="orange"
            borderRadius="md"
            placeholder="Postal Code"
            value={request.businessInfo.postalCode}
            onChange={(e) =>
              handleChangeBusinessInfo("postalCode", e.target.value)
            }
          />
        </GridItem>
      </Grid> */}

      {/* <HStack>
        <FormLabel w="max-content">Which month will you be incorporating in Alberta </FormLabel>
        <Select
          // defaultValue={request.businessInfo.fiscalYear}
          defaultValue={currentMonth}
          border="solid 2px black"
          maxW="max-content"
          onChange={(e) =>
            handleChangeBusinessInfo("fiscalYear", e.target.value)
          }
        >
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Select>
      </HStack> */}
      <Divider />
    </>
  );
};
export default ExtraBusInformation;
