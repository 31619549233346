import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  Input,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("Select");

  useEffect(() => {
    const data =
      request.partners.currentPartners &&
      request.partners.currentPartners.map(
        (partner) => `${partner.lastName}, ${partner.firstName}`
      );
    setList(data);
  }, [request.partners.currentPartners]);

  const handleRemovePartner = () => {
    let deletedPartner = null;
    console.log(selectedPartner);
    if (selectedPartner && selectedPartner !== "Select") {
      if (selectedPartner.includes(", ")) {
        const [lastName, firstName] = selectedPartner.split(", ");
        deletedPartner = request.partners.currentPartners.find(
          (partner) =>
            partner.firstName === firstName && partner.lastName === lastName
        );
      } else
        deletedPartner = request.partners.currentPartners.find(
          (partner) =>
            partner.firstName === selectedPartner ||
            partner.lastName === selectedPartner
        );
      if (deletedPartner) {
        setRequest((prevRequest) => ({
          ...prevRequest,
          partners: {
            ...prevRequest.partners,

            deletedPartners: [
              ...prevRequest.partners.deletedPartners,
              deletedPartner,
            ],
          },
        }));
        setList(
          list.filter(
            (partner) =>
              partner !==
              `${deletedPartner.lastName}, ${deletedPartner.firstName}`
          )
        );
      }

      setSelectedPartner(Select);
    }
  };

  const handleRestorePartner = (index) => {
    const restoredPartner = request.partners.deletedPartners[index];
    setRequest((prevRequest) => ({
      ...prevRequest,
      partners: {
        ...prevRequest.partners,
        deletedPartners: prevRequest.partners.deletedPartners.filter(
          (_, idx) => idx !== index
        ),
      },
    }));
    setList((prevList) => [
      ...prevList,
      `${restoredPartner.lastName}, ${restoredPartner.firstName}`,
    ]);

    setSelectedPartner(Select);
  };

  return (
    <>
      {console.log(request.partners)}
      <Heading as="h4" color="red.800">
        Removed Partners
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Partner to be removed:
          </FormLabel>
        </GridItem>
        <GridItem>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedPartner}
            defaultValue="Select"
            onChange={(e) => setSelectedPartner(e.target.value)}
          >
            <option>Select</option>
            {list.map((partner, index) => (
              <option key={index}>{partner}</option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemovePartner}
            disabled={!selectedPartner}
            aria-label="Remove Partner"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Partners that are being deleted are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.partners.deletedPartners &&
            request.partners.deletedPartners.map((partner, index) => (
              <ListItem key={index}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {partner.firstName} {partner.lastName}
                  </Text>
                  <Input type="date" width="150px" value={partner.revokeDate}/>
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleRestorePartner(index)}
                    aria-label="Restore Partner"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
