import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState([]);
  const [selectedShareholder, setSelectedShareholder] = useState("Select");

  useEffect(() => {
    const data =
      request.shareholders.currentShareholders &&
      request.shareholders.currentShareholders.map(
        (shareholder) => `${shareholder.lastName}${shareholder.corpName}, ${shareholder.firstName}${shareholder.corpAccessNumber}`
      );
    setList(data);
  }, [request.shareholders.currentShareholders]);

  const handleRemoveShareholder = () => {
    let deletedShareholder = null;
    console.log(selectedShareholder);
    if (selectedShareholder && selectedShareholder !== "Select") {
      if (selectedShareholder.includes(", ")) {
        const [lastName, firstName] = selectedShareholder.split(", ");
        deletedShareholder = request.shareholders.currentShareholders.find(
          (shareholder) =>
            shareholder.firstName === firstName && shareholder.lastName === lastName
        );
      } else
        deletedShareholder = request.shareholders.currentShareholders.find(
          (shareholder) =>
            shareholder.firstName === selectedShareholder ||
            shareholder.lastName === selectedShareholder
        );
      if (deletedShareholder) {
        setRequest((prevRequest) => ({
          ...prevRequest,
          shareholders: {
            ...prevRequest.shareholders,

            deletedShareholders: [
              ...prevRequest.shareholders.deletedShareholders,
              deletedShareholder,
            ],
          },
        }));
        setList(
          list.filter(
            (shareholder) =>
              shareholder !==
              `${deletedShareholder.lastName}, ${deletedShareholder.firstName}`
          )
        );
      }

      setSelectedShareholder(Select);
    }
  };

  const handleRestoreShareholder = (index) => {
    const restoredShareholder = request.shareholders.deletedShareholders[index];
    setRequest((prevRequest) => ({
      ...prevRequest,
      shareholders: {
        ...prevRequest.shareholders,
        deletedShareholders: prevRequest.shareholders.deletedShareholders.filter(
          (_, idx) => idx !== index
        ),
      },
    }));
    setList((prevList) => [
      ...prevList,
      `${restoredShareholder.lastName}, ${restoredShareholder.firstName}`,
    ]);

    setSelectedShareholder(Select);
  };

  return (
    <>
      {console.log(request.shareholders)}
      <Heading as="h4" color="red.800">
        Removed Shareholders
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Shareholder to be removed:
          </FormLabel>
        </GridItem>
        <GridItem>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedShareholder}
            defaultValue="Select"
            onChange={(e) => setSelectedShareholder(e.target.value)}
          >
            <option>Select</option>
            {list.map((shareholder, index) => (
              <option key={index}>{shareholder}</option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemoveShareholder}
            disabled={!selectedShareholder}
            aria-label="Remove Shareholder"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Shareholders that are being <i>deleted</i> are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.shareholders.deletedShareholders &&
            request.shareholders.deletedShareholders.map((shareholder, index) => (
              <ListItem key={index}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {shareholder.firstName} {shareholder.lastName}
                  </Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleRestoreShareholder(index)}
                    aria-label="Restore Shareholder"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
