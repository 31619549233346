import {
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Select,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";

const DirectorsAndOfficersInfo = ({ request, setRequest, companyType }) => {
  const [isEditable, setIsEditable] = useState(false);
  // const [officers, setOfficers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "Alberta",
  });

  // useEffect(() => {
  //   if (request.directors.managers === "") {
  //     setRequest((prevRequest) => ({
  //       ...prevRequest,
  //       directors: {
  //         managers: [{ ...request.accountInfo }],
  //         officershipType: "By Myself",
  //       },
  //     }));
  //   }
  // }, [request.officershipType]);

  const handleOfficershipTypeChange = (value) => {
    let managersBool = value == "By Myself" ? false : undefined;

    if (value === "By Myself") {
      setRequest((prevRequest) => ({
        ...prevRequest,
        directors: {
          officershipType: "By Myself",
          managers: ""
        }
      }));
    } else {
      setRequest((prevRequest) => ({
        ...prevRequest,
        directors: {
          officershipType: "With Others",
          managers: ""
        }
      }));
    }

    // if executing from NEW Corporation (Name/Numbered)
    if (companyType){
      setRequest((prevRequest) => ({
        ...prevRequest,
        reviewFieldHidden: {
          ...prevRequest.reviewFieldHidden,
          [companyType]: {
            ...prevRequest.reviewFieldHidden[companyType],
            directors: {
              ...prevRequest.reviewFieldHidden[companyType].directors,
              managers: managersBool
            }
          }
        }
      }));
    // else, executing from other form, eg. ExtraProvincial
    } else {
      setRequest((prevRequest) => ({
        ...prevRequest,
        reviewFieldHidden: {
          ...prevRequest.reviewFieldHidden,
          directors: {
            ...prevRequest.reviewFieldHidden.directors,
            managers: managersBool
          }
        }
      }));
    }

    // console.log('request.directors.managers', request.directors.managers);
  };

  const handleManagerAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
    setShowModal(true);
  };

  const handleEditOfficerManager = (manager) => {
    setFormData({ ...manager });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteOfficerManager = (key) => {
    if (window.confirm("Are you sure you want to delete this current director?")) {
      const updatedManagers = request.directors.managers.filter(
        (manager) => manager.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          managers: updatedManagers
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "Alberta"
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Check if all required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    // If it's editable, update the existing director, otherwise append new formData.
    setRequest((prevRequest) => {
      // Constructing updated managers inside setRequest to capture the current state correctly
      const updatedManagers = isEditable
        ? prevRequest.directors.managers.map((manager) =>
            manager.key === formData.key ? { ...manager, ...formData } : manager
          )
        : [...prevRequest.directors.managers, formData];

      return {
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          managers: updatedManagers,
        }
      };
    });

    setShowModal(false);
    setIsEditable(false);

    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  }

  return (
    <Box>
      <Flex justify="space-between" align="center">
        <Heading>Director(s) Information</Heading>
      </Flex>
      <Heading as="h3" mt="24px !important">
        Company Directors
      </Heading>
      {/* officership single or more */}
      <HStack>
        <FormLabel w="max-content">I will manage this business</FormLabel>
        <Select
          w="30ch"
          autoFocus
          borderBottom="solid 1px black"
          value={request.directors.officershipType}
          onChange={(e) => handleOfficershipTypeChange(e.target.value)}
        >
          <option value="By Myself">By Myself</option>
          <option value="With Others">With Others</option>
        </Select>
      </HStack>
      {/* Officers and Managers Lists */}
      <Divider h="50px" />
      <Box>
        <HStack justify={"space-between"} py="5">
          <Heading as="h4" color="blue.700" mb="0 !important">
            Current Directors
          </Heading>
          {request.directors &&
            request.directors.officershipType === "With Others" && (
              <Button
                colorScheme="blue"
                variant="solid"
                leftIcon={<AddIcon />}
                onClick={handleManagerAdd}
              >
                Add
              </Button>
            )}
        </HStack>
        {request.directors.managers &&
          request.directors.managers.length > 0 && (
            <List
              borderBottom="solid 1px gray"
              ml={{base:"0", md:"10%"}}
              w={{base:"100%", md:"60%"}}
              color="blue.500"
            >
              {request.directors.managers.map((manager, index) => (
                <ListItem key={manager.key}>
                  <HStack justify="space-between">
                    <Text fontWeight="bold" mb="0 !important">
                      {manager.firstName} {manager.lastName}
                    </Text>
                    <HStack>
                      <IconButton
                        icon={<EditIcon />}
                        onClick={() => handleEditOfficerManager(manager)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() =>
                          handleDeleteOfficerManager(manager.key)
                        }
                      />
                    </HStack>
                  </HStack>
                </ListItem>
              ))}
            </List>
          )}
      </Box>

      <Divider h="50px" />

      <Modal
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable ? "Edit Director" : "Add Director"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>First Name:</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Last Name:</FormLabel>
              <Input
                placeholder="Last Name / Corporate Name (full)"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                mb="4"
              />
              <Text fontWeight="bold">Residential Address:</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input                      
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Province</FormLabel>
                      <Input
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DirectorsAndOfficersInfo;
